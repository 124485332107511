import { useState } from 'react';
import dayjs from "dayjs";

export default function useSearchForm() {
  const defaultStartDate = dayjs().utc(true).add(-30, 'days')
  const defaultEndDate = dayjs().utc(true)

  const [reloadList, setReloadList] = useState(0)

  const [
    search,
    setSearch
  ] = useState<API.FinanceSearchForm>({
    period: [
      defaultStartDate,
      defaultEndDate
    ],
  })
  const handleChangeSearch = (value: API.FinanceSearchForm) => {
    setSearch((prevSearch) => ({...prevSearch, ...value}))
  }

  const resetSearch = () => {
    setSearch({period: [defaultStartDate, defaultEndDate]})
  }

  const handleReloadList = () => {
    setReloadList((val) => val + 1)
  }

  return {
    search, resetSearch, handleChangeSearch, reloadList, handleReloadList
  };
}
