// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import AppstoreOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/AppstoreOutlined';
import TeamOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/TeamOutlined';
import AuditOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/AuditOutlined';
import WarningOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/WarningOutlined';
import ToolOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/ToolOutlined';
import RedditOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/RedditOutlined';
import CalendarOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/CalendarOutlined';
import OrderedListOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/OrderedListOutlined';
import UserOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/UserOutlined';
import DollarOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/DollarOutlined';
import BarChartOutlined from '/home/app/xrem24.com/shared/node_modules/@umijs/plugins/node_modules/@ant-design/icons/es/icons/BarChartOutlined';
export default { AppstoreOutlined, TeamOutlined, AuditOutlined, WarningOutlined, ToolOutlined, RedditOutlined, CalendarOutlined, OrderedListOutlined, UserOutlined, DollarOutlined, BarChartOutlined };
