export default {
  'menu.login': 'Страница входа',
  'menu.forgot-password': 'Восстановление пароля',
  'menu.master-first-login': 'Новый мастер',
  'menu.welcome': 'Страница приветствия',
  'menu.desktop': 'Рабочий стол',
  'menu.desktop-master': 'Рабочий стол',
  'menu.my-orders': 'Мои заказы',
  'menu.my-order': 'Заказ',
  'menu.orders': 'Заказы',
  'menu.long-repairs': 'ДРы',
  'menu.order': 'Заказ',
  'menu.leads': 'Заявки',
  'menu.lead': 'Заявка',
  'menu.masters': 'Мастера',
  'menu.master': 'Карточка мастера',
  'menu.new-master': 'Новый мастер',
  'menu.master-invite': 'Подключение мастера',
  'menu.customers': 'Клиенты',
  'menu.customer': 'Карточка клиента',
  'menu.my-working-time': 'Мой график работы',
  'menu.lists': 'Списки',
  'menu.lists.branches': 'Филиалы',
  'menu.lists.sites': 'Сайты',
  'menu.lists.brands': 'Бренды',
  'menu.lists.appliances': 'Виды техники',
  'menu.lists.phones': 'Телефоны',
  'menu.lists.phone_groups': 'Группы',
  'menu.lists.users': 'Пользователи',
  'menu.profile': 'Мой профиль',
  'menu.finance': 'Финансы',
  'menu.finance-show': 'Отчет',
  'menu.planning': 'Планёрка',
  'menu.reports': 'Отчеты',
  'menu.reports.branches': 'Филиалы',
  'menu.reports.debts': 'Долги',
  'menu.reports.appliances': 'Техника',
  'menu.reports.masters': 'Мастера',

  'orders.form.statuses.null': 'Нет статуса',
  'orders.form.statuses.in_decision': 'Подумают',
  'orders.form.statuses.visit_master': 'Выезд мастера',
  'orders.form.statuses.bring_to_service': 'Привезут',
  'orders.form.statuses.long_repair': 'Долгий ремонт',
  'orders.form.statuses.done': 'Готов',
  'orders.form.statuses.canceled': 'Отмена',
  'orders.form.statuses.waiting_payment': 'Ожидает оплаты',
  'orders.form.statuses.paid': 'Оплачено',
  'orders.form.statuses.diagnostics': 'Диагностика',

  'orders.form.orderExpenses.transport': 'Доставка',
  'orders.form.orderExpenses.spare': 'Запчасть',
  'orders.form.orderExpenses.module': 'Модульщик',

  'orders.payment_types.cash': 'Нал.',
  'orders.payment_types.erip': 'Карта/ЕРИП',
  'orders.payment_types.bank': 'Безнал',

  'orders.filters.all': 'Все заказы',
  'orders.filters.all_opened': 'Все открытые',
  'orders.filters.all_closed': 'Все закрытые',
  'orders.filters.pending_payment': 'Неоплаченные',
  'orders.filters.time.yesterday': 'Вчера',
  'orders.filters.time.today': 'Сегодня',
  'orders.filters.time.tomorrow': 'Завтра',
  'orders.filters.long_repairs': 'ДРы',
  'orders.filters.bring_to_service': 'Привезут',
  'orders.filters.in_decision': 'Подумают',
  'orders.filters.forgotten': 'Забытые',
  'orders.filters.balance': 'Баланс кассы',

  'customers.types.person': 'Физ. лицо',
  'customers.types.organization': 'Юр. лицо',

  'master_cashes.types.revenue': 'Выручка',
  'master_cashes.types.correction': 'Коррекция',
  'master_cashes.types.penalty': 'Штраф',

  'masters.banned.none': 'Нет',
  'masters.banned.master': 'Заявки',
  'masters.banned.user': 'Заявки и Вход',

  'leads.contact_types.form': 'Через сайт',
  'leads.contact_types.call': 'Звонок',
  'leads.contact_types.tiktok': 'Tiktok',
  'leads.contact_types.google': 'Google',

  'users.roles.super_admin': 'Супер Админ',
  'users.roles.admin': 'Админ',
  'users.roles.manager': 'Менеджер',
  'users.roles.accountant': 'Бухгалтер',
  'users.roles.operator': 'Оператор',
  'users.roles.master': 'Мастер',
  'users.roles.hr': 'HR',

  'phones.types.base_phone': 'Базовый',
  'phones.types.tracking_phone': 'Трекинговый',
  'phones.types.operator_phone': 'Операторский',

  'histories.types.comment_order': 'Новый комментарий к заказу',
  'histories.types.change_order_time': 'Перенос заказа',
  'histories.types.set_master': 'Установлен новый мастер',
  'histories.types.delete_master': 'Мастер снят с заказа',
  'histories.types.update_order': 'Заказ изменен',
  'histories.types.cancel_order': 'Заказ отменен',
  'histories.types.complaint_order': 'Жалоба',
  'histories.types.warranty_order': 'Гарантия',
  'histories.types.new_lead_form': 'Новая заявка (через сайт)',
  'histories.types.new_lead_tiktok': 'Новая заявка (через тикток)',
  'histories.types.new_lead_google': 'Новая заявка (через google)',
  'histories.types.new_lead_call': 'Новая заявка (звонок)',
  'histories.types.inbound_call': 'Входящий звонок',
  'histories.types.outbound_call': 'Исходящий звонок',
  'histories.types.new_order': 'Новый заказ',
  'histories.types.almost_order': 'Почти заказ',
  'histories.types.wrong_lead': 'Ошибочная заявка',
  'histories.types.done_order': 'Заказ выполнен',
  'histories.types.long_repair_order': 'Долгий ремонт',
  'histories.types.diagnostics_order': 'Диагностика',
  'histories.types.in_decision_order': 'Подумают',
  'histories.types.reopen_order': 'Заказ переоткрыт',
  'histories.types.paid_order': 'Заказ оплачен',

  'histories.fields.time': 'Время',
  'histories.fields.master': 'Мастер',
  'histories.fields.cost': 'Сумма в чеке',
  'histories.fields.spending_transport': 'Затраты на транспорт',
  'histories.fields.spending_spares': 'Затраты на запчасти',
  'histories.fields.spending_module': 'Затраты на рем. модуля',

  'schedules.schedule_types.daily': 'Ежедневно',
  'schedules.schedule_types.6_1': '6/1',
  'schedules.schedule_types.5_2': '5/2',
  'schedules.schedule_types.2_2': '2/2',
  'schedules.schedule_types.2_2_3': '2/2/3',
  'schedules.schedule_types.custom': 'Свой',
  'schedules.schedule_types.none': 'Свободный',

  'region_payments.types.revenue': 'Выручка',
  'region_payments.types.ads': 'Реклама',
  'region_payments.types.correction': 'Коррекция',

  'reports.quantity': 'Заказов',
  'reports.done_quantity': 'Заказов (готов.)',
  'reports.canceled_quantity': 'Заказов (отм.)',
  'reports.diagnostics_quantity': 'Заказов (диагн.)',
  'reports.in_progress_quantity': 'Заказов (в работ.)',
  'reports.in_decision_quantity': 'Заказов (дум.)',
  'reports.avg_quantity': 'Заказов (ср/дн)',
  'reports.ads_cost': 'Реклама',
  'reports.avg_ads_cost': 'Реклама (ср/дн)',
  'reports.cpo': 'Цена заказа',
  'reports.profit': 'Прибыль',
  'reports.avg_profit': 'Прибыль (ср/дн)',
  'reports.avg_profit_o': 'Прибыль (ср/заказ)',
  'reports.price': 'Чек',
  'reports.avg_price_order': 'Чек (ср/заказ)',
  'reports.avg_price': 'Чек (ср/дн)',
  'reports.roi': 'ROI',
  'reports.clear_profit': 'Прибыль (чистая)'
};
